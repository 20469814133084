import { Component } from '@angular/core';
import { FixtureService } from '../services';
import { CoreService } from '../../core/services';

@Component({
  selector: 'app-fixtures-page',
  template: `
    <mat-card>
      <mat-card-title>
        Mängud
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="create()">Lisa mäng</button>
      </mat-card-title>
      <mat-card-content>
        <div class="mat-elevation-z8" *ngIf="(fixtureService.fixtures | async) != null">
          <div class="mat-table">
            <div fxLayout="row" class="mat-header-row x-header-row">
              <div fxFlex="30%" class="mat-header-cell">Algus</div>
              <div fxFlex="30%" class="mat-header-cell">Mäng</div>
              <div fxFlex="10%" class="mat-header-cell">Tulemus</div>
              <div fxFlex="20%" class="mat-header-cell">Võitja</div>
              <div fxFlex="10%" class="mat-header-cell"></div>
            </div>
            <ng-container *ngFor="let fixture of fixtureService.fixtures | async">
              <app-fixture-row-admin *ngIf="user.role == 'ROLE_ADMIN'" [fixture]="fixture"></app-fixture-row-admin>
            </ng-container>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class FixturesPageComponent {
  user: any;

  constructor(private coreService: CoreService, public fixtureService: FixtureService) {
    this.fixtureService.loadFixtures();
    this.coreService.signedInUser.subscribe((user: any) => (this.user = user));
  }

  create() {
    const d = new Date();
    d.setHours(18);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    const fixture = { predictionEndsAt: d };
    this.fixtureService.create(fixture);
  }
}
