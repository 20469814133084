import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CoreService } from 'src/app/core/services';

@Injectable()
export class PredictionService {
  private apiUrl = '/api/cups/predictions';

  private userPredictionsSubject = new BehaviorSubject<any>(null);
  userPredictions$ = this.userPredictionsSubject.asObservable();
  private fixturePredictionsSubject = new BehaviorSubject<any>(null);
  fixturePredictions$ = this.fixturePredictionsSubject.asObservable();
  private resultsSubject = new BehaviorSubject<any>(null);
  results$ = this.resultsSubject.asObservable();

  cupSubject = new BehaviorSubject<any>(null);

  constructor(private httpClient: HttpClient, private coreService: CoreService) {}

  loadPredictionsForUser(user: any): void {
    this.userPredictionsSubject.next(null);
    this.httpClient
      .get(`${this.apiUrl}?userId=${user.id}`)
      .pipe(take(1))
      .subscribe((predictions) => this.userPredictionsSubject.next(predictions));
  }

  loadPredictionsForFixture(fixture: any): void {
    this.fixturePredictionsSubject.next(null);
    this.httpClient
      .get(`${this.apiUrl}?fixtureId=${fixture.id}`)
      .pipe(take(1))
      .subscribe((predictions) => this.fixturePredictionsSubject.next(predictions));
  }

  loadResults(): void {
    this.coreService.setLoading.next(true);

    this.httpClient
      .get(`${this.apiUrl}/results`)
      .pipe(take(1))
      .subscribe((results) => {
        this.resultsSubject.next(results);
        this.coreService.setLoading.next(false);
      });
  }
}
