import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MessageService } from '../services';
import { ChatChannel, ChatMessage } from '../models';

@Component({
  selector: 'app-message-board-page',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-sidenav-container>
      <mat-sidenav #snav position="end" style="width: 300px" [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="!mobileQuery.matches">
        <mat-accordion displayMode="flat" multi>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>Vestlused</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-selection-list #channelList [multiple]="false" (selectionChange)="channelSelected($event)">
              <mat-list-option
                *ngFor="let item of channels"
                [value]="item"
                [selected]="item.id == channel?.id"
                matBadge="!"
                matBadgeSize="small"
                matBadgePosition="before"
                [matBadgeHidden]="!item.hasUnread"
                matBadgeColor="accent"
              >
                {{ item.name }}
              </mat-list-option>
            </mat-selection-list>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>Kasutajad</mat-panel-title>
            </mat-expansion-panel-header>
            <p>Tulevad varsti</p>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-sidenav>
      <mat-sidenav-content>
        <div style="min-height: 90vh; background-color: #e2e2e2">
          <div style="padding-bottom: 16px; padding-right: 6px">
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{ channel?.name }}</mat-card-title>
                <span style="flex: 1 1 auto"></span>
                <button mat-icon-button style="margin-top: -12px" color="accent" (click)="snav.toggle()">
                  <mat-icon *ngIf="snav.opened">chevron_right</mat-icon><mat-icon *ngIf="!snav.opened">chevron_left</mat-icon>
                </button>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="row" style="padding-left: 4px; margin-bottom: 8px;">
                  <div fxFlex>
                    <app-message-add></app-message-add>
                  </div>
                  <div fxFlex="nogrow" style="padding-top: 7px; padding-left: 4px">
                    <button mat-icon-button color="primary" (click)="refresh()" title="Värskenda"><mat-icon>refresh</mat-icon></button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <ng-container *ngIf="messages !== null">
            <div *ngFor="let message of messages" style="padding-bottom: 16px; padding-right: 6px">
              <app-message-view [message]="message"></app-message-view>
            </div>
          </ng-container>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
})
export class MessageBoardPageComponent implements OnDestroy {
  channels: ChatChannel[] | null;
  channel: ChatChannel | null;
  messages?: ChatMessage[];
  replies?: ChatMessage[];
  mobileQuery: MediaQueryList;
  displaySnackBar = false;

  private mobileQueryListener: () => void;
  constructor(private snackBar: MatSnackBar, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public messageService: MessageService) {
    this.channels = this.messageService.channelsSubject.getValue();
    this.channel = this.messageService.channelSubject.getValue();
    this.messageService.channelsSubject.subscribe((channels) => {
      this.channels = channels;
    });
    this.messageService.messagesSubject.subscribe((messages) => {
      if (!messages) {
        return;
      }
      this.messages = messages.filter((m: ChatMessage) => !m.parentId);
      this.replies = messages.filter((m: ChatMessage) => m.parentId);
      this.messages.map((m: any) => {
        m.replies = this.replies!.filter((r: ChatMessage) => r.parentId === m.id);
        m.ord = +m.id;
        m.replies.map((r: ChatMessage) => {
          const id = r.id ? +r.id : 0;
          if (m.ord < id) m.ord = id;
        });
      });
      this.messages = this.messages.sort((a, b) => (a?.ord! > b?.ord! ? 0 : 1));

      if (this.displaySnackBar) {
        this.snackBar.open('Uuendatud');
        this.displaySnackBar = false;
      }
    });

    this.messageService.channelSubject.subscribe((channel) => {
      if (channel) {
        this.channel = channel;
        this.messageService.loadMessages(channel.id!);
      }
    });

    // setup resize listener
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
  }

  channelSelected(event: MatSelectionListChange) {
    this.messageService.channelSubject.next(event.options[0].value);
  }

  refresh() {
    this.messageService.loadChannels();
    this.messageService.loadMessages(this.channel!.id!);
    this.displaySnackBar = true;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
  }
}
