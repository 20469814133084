import { Fixture, FixturePrediction } from './../models/index';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FixtureService, PredictionService, TeamService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rules-page',
  template: `
    <mat-card *ngIf="fixture">
      <mat-card-title>
        <div style="max-width: 500px">
          <div fxLayout="row">
            <div fxFlex="45%" style="text-align: right"><img src="/assets/images/flags/{{ homeTeam?.country | lowercase }}.webp" /></div>
            <div fxFlex="10%"></div>
            <div fxFlex="45%"><img src="/assets/images/flags/{{ awayTeam?.country | lowercase }}.webp" /></div>
          </div>
          <div fxLayout="row">
            <div fxFlex="45%" style="text-align: right">{{ homeTeam?.nameEt }}</div>
            <div fxFlex="10%" style="text-align: center">vs.</div>
            <div fxFlex="45%">{{ awayTeam?.nameEt }}</div>
          </div>
          <div fxLayout="row">
            <div fxFlex="45%" style="text-align: right">{{ fixture.homeScore }}</div>
            <div fxFlex="10%" style="text-align: center">-</div>
            <div fxFlex="45%">{{ fixture.awayScore }}</div>
          </div>
        </div>
      </mat-card-title>
      <mat-card-content>
        <div class="mat-elevation-z8" *ngIf="stats != null" style="max-width: 500px; margin-bottom: 4px">
          <div fxLayout="row" class="mat-header-row x-header-row">
            <div fxFlex style="text-align: right">Ennustajaid:</div>
            <div fxFlex="nogrow" style="width: 10px"></div>
            <div fxFlex="nogrow" style="width: 200px">
              {{ stats.users }}
            </div>
          </div>
          <div fxLayout="row" class="mat-header-row x-header-row">
            <div fxFlex style="text-align: right">{{ homeTeam?.nameEt }} võitu ennustasid:</div>
            <div fxFlex="nogrow" style="width: 10px"></div>
            <div fxFlex="nogrow" style="width: 200px">
              {{ stats.homeWins }}
            </div>
          </div>
          <div fxLayout="row" class="mat-header-row x-header-row">
            <div fxFlex style="text-align: right">{{ awayTeam?.nameEt }} võitu ennustasid:</div>
            <div fxFlex="nogrow" style="width: 10px"></div>
            <div fxFlex="nogrow" style="width: 200px">
              {{ stats.awayWins }}
            </div>
          </div>
          <div fxLayout="row" class="mat-header-row x-header-row">
            <div fxFlex style="text-align: right">Viiki ennustasid:</div>
            <div fxFlex="nogrow" style="width: 10px"></div>
            <div fxFlex="nogrow" style="width: 200px">
              {{ stats.draws }}
            </div>
          </div>
        </div>
        <div class="mat-elevation-z8" *ngIf="predictions != null" style="max-width: 500px">
          <div class="mat-table">
            <div fxLayout="row" class="mat-header-row x-header-row">
              <div fxFlex="40%" class="mat-header-cell">Ennustaja</div>
              <div fxFlex="30%" class="mat-header-cell">Ennustus</div>
              <div fxFlex="30%" class="mat-header-cell">punktid</div>
            </div>

            <div fxLayout="row" class="mat-row x-row" *ngFor="let prediction of predictions">
              <div fxFlex="40%" class="mat-cell">
                <a [routerLink]="['/user-predictions', prediction.user!.id]">{{ prediction.user!.nickname }}</a>
              </div>
              <div fxFlex="30%" class="mat-cell">{{ prediction.homeScore }} - {{ prediction.awayScore }}</div>
              <div fxFlex="30%" class="mat-cell">{{ prediction.points }}</div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class PredictionViewPageComponent implements OnInit, OnDestroy {
  unsubscriber$: Subject<boolean> = new Subject<boolean>();
  fixture: Fixture | null = null;
  predictions: FixturePrediction[] | null = null;
  stats: any;
  homeTeam: any;
  awayTeam: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private fixtureService: FixtureService,
    private predictionService: PredictionService,
    private teamService: TeamService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.pipe(takeUntil(this.unsubscriber$)).subscribe((params) => {
      this.fixtureService.loadFixture(params.id);
      this.predictionService.loadPredictionsForFixture({ id: params.id });
    });

    this.fixtureService.selectedFixture.pipe(takeUntil(this.unsubscriber$)).subscribe((fixture) => {
      this.fixture = fixture;
      if (fixture) {
        this.homeTeam = this.teamService.getById(fixture.homeTeamId!);
        this.awayTeam = this.teamService.getById(fixture.awayTeamId!);
      }
    });

    this.predictionService.fixturePredictions$.pipe(takeUntil(this.unsubscriber$)).subscribe((predictions) => {
      this.predictions = predictions;
      this.stats = this.calculateStatistics(predictions);
    });
  }

  calculateStatistics(predictions: any) {
    if (!predictions) {
      return null;
    }

    const stats = <any>{};
    stats.users = predictions.length;
    stats.homeWins = 0;
    stats.awayWins = 0;
    stats.draws = 0;
    predictions.forEach((p: any) => {
      if (p.homeScore != null && p.awayScore != null) {
        if (p.homeScore > p.awayScore) {
          stats.homeWins++;
        } else if (p.homeScore < p.awayScore) {
          stats.awayWins++;
        } else {
          stats.draws++;
        }
      }
    });

    return stats;
  }
  ngOnDestroy() {
    this.unsubscriber$.next(true);
    this.unsubscriber$.unsubscribe();
  }
}
