import { User } from './../models/index';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  signedInUser = new BehaviorSubject<User | null>(null);
  setLoading: Subject<boolean> = new Subject();
  error: Subject<string> = new Subject();

  constructor(private http: HttpClient, private router: Router) {}

  async init(): Promise<void> {
    try {
      this.signedInUser.next(await this.getCurrentUser());
    } catch (e) {
      this.signedInUser.next(null);
    }
    // this.signedInUserSubject.next({ id: 1, nickname: 'nick', role: 'ROLE_ADMIN' });
  }

  signUp(user: { email: string; password: string }): Observable<any> {
    return this.http.post('/api/sign-up', user).pipe(take(1));
  }

  signIn(credentials: { email: string; password: string }): Observable<any> {
    return this.http.post('/api/sign-in', credentials).pipe(take(1));
  }

  async signOut(): Promise<void> {
    this.http.post('/api/sign-out', {}).pipe(take(1)).subscribe();
    try {
      this.signedInUser.next(null);
      await this.router.navigate(['sign-in']);
    } catch (err) {
      console.error(err);
    }
  }

  saveCurrentUser(user: User): Observable<User> {
    user.id = undefined;
    return this.http.put<User>('/api/core/users', user).pipe(take(1));
  }

  markAllChatMessagesAsRead(maxMsgId: number): void {
    // sets only the value for lastSeenChatMessageId of the current user
    this.http.put<User>(`/api/core/users?lastSeenChatMessageId=${maxMsgId}`, {}).pipe(take(1)).subscribe();
  }

  handleHttpError = (error: HttpErrorResponse) => {
    // error.status === 0
    this.error.next(error.error ?? 'Päring ebaõnnestus.');
    return throwError('API error occured');
  };

  private async getCurrentUser(): Promise<User> {
    return this.http.get<User>('/api/core/users?current').toPromise();
  }
}
