import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Team } from '../models';

@Injectable()
export class TeamService {
  private apiUrl = '/api/cups/teams';

  teams = new BehaviorSubject<Team[] | null>(null);

  constructor(private httpClient: HttpClient) {}

  load(): void {
    this.teams.next(null);
    this.httpClient
      .get<Team[]>(this.apiUrl)
      .pipe(take(1))
      .subscribe((teams) => this.teams.next(teams));
  }

  getById(id: number): any {
    return this.teams.value!.find((t: any) => t.id == id);
  }

  compareById(o1: any, o2: any) {
    return o1 !== null && o2 !== null && o1.id === o2.id;
  }
}
