import { Component } from '@angular/core';
import { FixtureService, PredictionService } from '../services';
import { CoreService } from '../../core/services';

@Component({
  selector: 'app-prediction-page',
  template: `
    <mat-card>
      <mat-card-title> Ennustamine </mat-card-title>
      <app-user-cup-predicton [user]="user" *ngIf="user"></app-user-cup-predicton>
      <mat-card-content>
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Toimumata">
            <div
              class="mat-elevation-z8"
              *ngIf="(fixtureService.fixturesToCome | async) !== null && (fixtureService.fixturesToCome | async)?.length! > 0; else noFixturesToCome"
            >
              <div class="mat-table">
                <div fxLayout="row" class="mat-header-row x-header-row" fxShow.lt-md="false">
                  <div fxFlex="20%" class="mat-header-cell">Algus</div>
                  <div fxFlex="30%" class="mat-header-cell">Mäng</div>
                  <div fxFlex="10%" class="mat-header-cell">Tulemus</div>
                  <div fxFlex="20%" class="mat-header-cell">Minu ennustus</div>
                  <div fxFlex="10%" class="mat-header-cell">Minu punktid</div>
                  <div fxFlex="10%" class="mat-header-cell"></div>
                </div>
                <ng-container *ngFor="let fixture of fixtureService.fixturesToCome | async">
                  <app-fixture-row [canPredict]="true" [fixture]="fixture"></app-fixture-row>
                </ng-container>
              </div>
            </div>
            <ng-template #noFixturesToCome>
              <div style="padding: 15px 0">Mängud puuduvad</div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Toimunud">
            <div
              class="mat-elevation-z8"
              *ngIf="(fixtureService.fixturesDone | async) !== null && (fixtureService.fixturesDone | async)?.length! > 0; else noDoneFixtures"
            >
              <div class="mat-table">
                <div fxLayout="row" class="mat-header-row x-header-row" fxShow.lt-md="false">
                  <div fxFlex="20%" class="mat-header-cell">Algus</div>
                  <div fxFlex="30%" class="mat-header-cell">Mäng</div>
                  <div fxFlex="10%" class="mat-header-cell">Tulemus</div>
                  <div fxFlex="20%" class="mat-header-cell">Minu ennustus</div>
                  <div fxFlex="10%" class="mat-header-cell">Minu punktid</div>
                  <div fxFlex="10%" class="mat-header-cell"></div>
                </div>
                <ng-container *ngFor="let fixture of fixtureService.fixturesDone | async">
                  <app-fixture-row [canPredict]="false" [fixture]="fixture"></app-fixture-row>
                </ng-container>
              </div>
            </div>
            <ng-template #noDoneFixtures>
              <div style="padding: 15px 0">Mängud puuduvad</div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  `,
})
export class PredictionsPageComponent {
  user: any;

  constructor(private coreService: CoreService, public fixtureService: FixtureService, private predictionService: PredictionService) {
    this.coreService.signedInUser.subscribe((user: any) => {
      this.user = user;
      if (user != null) {
        this.predictionService.loadPredictionsForUser(user);
      }
    });
    this.fixtureService.loadFixtures();
  }
}
