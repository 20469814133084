import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { AuthGuardService } from '../core/services';

import { CupService, FixtureService, PredictionService, TeamService } from './services';
import {
  FixturesPageComponent,
  PredictionsPageComponent,
  PredictionViewPageComponent,
  ResultsPageComponent,
  RulesPageComponent,
  UserPredictionsPageComponent,
} from './views';
import { FixtureRowAdminComponent, FixtureRowComponent, UserCupPredictionComponent } from './components';

const routes: Routes = [
  { path: 'predictions', component: PredictionsPageComponent, canActivate: [AuthGuardService] },
  { path: 'predictions/:id', component: PredictionViewPageComponent, canActivate: [AuthGuardService] },
  { path: 'results', component: ResultsPageComponent, canActivate: [AuthGuardService] },
  { path: 'rules', component: RulesPageComponent, canActivate: [AuthGuardService] },
  { path: 'fixtures', component: FixturesPageComponent, canActivate: [AuthGuardService] },
  { path: 'user-predictions/:id', component: UserPredictionsPageComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    FixturesPageComponent,
    PredictionsPageComponent,
    PredictionViewPageComponent,
    ResultsPageComponent,
    RulesPageComponent,
    UserPredictionsPageComponent,

    FixtureRowAdminComponent,
    FixtureRowComponent,
    UserCupPredictionComponent,
  ],
  providers: [CupService, FixtureService, PredictionService, TeamService],
})
export class CupModule {}
