import { Component, Input, OnInit } from '@angular/core';
import { PredictionService, TeamService } from '../services';

// @Component({
//   selector: 'app-fixture-row-view',
//   template: `
//     <div fxLayout="row" class="mat-row x-row">
//       <div fxFlex="20%" class="mat-cell">
//         {{ fixture.predictionEndsAt | date: 'dd.MM.yy HH:mm' }}
//       </div>
//       <div fxFlex="30%" class="mat-cell">
//         <a [routerLink]="['/predictions', fixture.id]">{{ homeTeam?.nameEt }} - {{ awayTeam?.nameEt }}</a>
//       </div>
//       <div fxFlex="10%" class="mat-cell">
//         <span *ngIf="fixture.homeScore != null">{{ fixture.homeScore }}</span> -
//         <span *ngIf="fixture.awayScore != null">{{ fixture.awayScore }}</span>
//       </div>
//       <div fxFlex="20%" class="mat-cell">
//         <span *ngIf="prediction && prediction.homeScore != null">{{ prediction.homeScore }}</span> -
//         <span *ngIf="prediction && prediction.awayScore != null">{{ prediction.awayScore }}</span>
//       </div>
//       <div fxFlex="10%" class="mat-cell">
//         <span *ngIf="prediction && prediction.points != null">{{ prediction.points }}</span>
//       </div>
//       <div fxFlex="10%" class="mat-header-cell" fxLayoutAlign="end">&nbsp;</div>
//     </div>
//   `,
// })
// export class FixtureRowViewComponent implements OnInit {
//   @Input() fixture: any;
//   prediction: any;
//   homeTeam: any;
//   awayTeam: any;

//   constructor(private predictionService: PredictionService, private teamService: TeamService) {}

//   ngOnInit() {
//     this.homeTeam = this.teamService.getById(this.fixture.homeTeamId);
//     this.awayTeam = this.teamService.getById(this.fixture.awayTeamId);
//     this.predictionService.userPredictions$.subscribe((predictions) => {
//       if (predictions != null) {
//         this.prediction = predictions.find((p: any) => p.fixtureId === this.fixture.id);
//       }
//     });
//   }
// }
