import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../core/models';
import { UserService } from '../../core/services';
import { Fixture, FixturePrediction } from '../models';
import { FixtureService, PredictionService, TeamService } from '../services';

@Component({
  selector: 'app-user-predictions-page',
  template: `
    <mat-card>
      <mat-card-title>
        <ng-container *ngIf="user">Kasutaja {{ user.nickname }} ennustab</ng-container>
      </mat-card-title>
      <mat-card-content>
        <app-user-cup-predicton [user]="user" *ngIf="user"></app-user-cup-predicton>
        <div class="mat-elevation-z8" *ngIf="dataRows != null">
          <div class="mat-table">
            <div fxLayout="row" class="mat-header-row x-header-row">
              <div fxFlex="20%" class="mat-header-cell">Algus</div>
              <div fxFlex="30%" class="mat-header-cell">Mäng</div>
              <div fxFlex="20%" class="mat-header-cell">Tulemus</div>
              <div fxFlex="20%" class="mat-header-cell">Ennustus</div>
              <div fxFlex="10%" class="mat-header-cell">Punktid</div>
            </div>
            <div fxLayout="row" class="mat-row x-row" *ngFor="let row of dataRows">
              <div fxFlex="20%" class="mat-cell">
                {{ row.fixture.predictionEndsAt | date: 'dd.MM.yy HH:mm' }}
              </div>
              <div fxFlex="30%" class="mat-cell">
                <a [routerLink]="['/predictions', row.fixture.id]">{{ row.homeTeam?.nameEt }} - {{ row.awayTeam?.nameEt }}</a>
              </div>
              <div fxFlex="20%" class="mat-cell">{{ row.fixture.homeScore }} - {{ row.fixture.awayScore }}</div>
              <div fxFlex="20%" class="mat-cell">{{ row.prediction?.homeScore }} - {{ row.prediction?.awayScore }}</div>
              <div fxFlex="10%" class="mat-cell">
                {{ row.prediction?.points }}
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class UserPredictionsPageComponent implements OnInit, OnDestroy {
  unsubscriber$: Subject<boolean> = new Subject<boolean>();
  user: User | null = null;
  fixtures: Fixture[] | null = null;
  predictions: FixturePrediction[] | null = null;

  dataRows: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fixtureService: FixtureService,
    private predictionService: PredictionService,
    private userService: UserService,
    private teamService: TeamService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.pipe(takeUntil(this.unsubscriber$)).subscribe((params) => {
      this.predictionService.loadPredictionsForUser({ id: params.id });
      this.userService.loadUser(params.id);
    });

    this.fixtureService.fixtures.pipe(takeUntil(this.unsubscriber$)).subscribe((fixtures) => {
      if (fixtures == null) {
        return;
      }
      if (this.predictions) {
        this.dataRows = this.composeRows(fixtures, this.predictions);
      }
    });

    this.predictionService.userPredictions$.pipe(takeUntil(this.unsubscriber$)).subscribe((predictions) => {
      if (predictions == null) {
        return;
      }

      if (this.fixtures) {
        this.dataRows = this.composeRows(this.fixtures, predictions);
      } else {
        this.fixtureService.loadFixtures();
      }

      this.predictions = predictions;
    });

    this.userService.user$.pipe(takeUntil(this.unsubscriber$)).subscribe((user) => (this.user = user));
  }

  private composeRows(fixtures: any, predictions: any): any[] {
    return fixtures.map((fixture: any) => {
      return {
        fixture,
        homeTeam: this.teamService.getById(fixture.homeTeamId),
        awayTeam: this.teamService.getById(fixture.awayTeamId),
        prediction: predictions.find((p: any) => p.fixtureId === fixture.id),
      };
    });
  }

  ngOnDestroy() {
    this.unsubscriber$.next(true);
    this.unsubscriber$.unsubscribe();
  }
}
