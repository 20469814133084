import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hasValue' })
export class HasValuePipe implements PipeTransform {
  transform(value: any, defaultValue?: any): any {
    if (value === undefined || value === null) {
      return defaultValue;
    }

    return value;
  }
}
