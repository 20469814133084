import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CoreService } from '../services';

@Component({
  selector: 'app-sign-in-page',
  template: `
    <div fxLayout.gt-xs="column">
      <div fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center center">
        <div fxFlex.gt-xs="1 1 450px">
          <mat-card>
            <mat-card-title>Sign in</mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="10px">
                <form [formGroup]="form" (ngSubmit)="signIn()" autocomplete="off" novalidate>
                  <div fxLayout="column">
                    <mat-form-field appearance="outline">
                      <input matInput placeholder="Email" formControlName="email" />
                      <mat-error>This field is required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <input type="password" matInput placeholder="Password" formControlName="password" />
                      <mat-error>This field is required</mat-error>
                    </mat-form-field>
                    <button mat-raised-button color="primary" style="margin-top: 5px">Sign in</button>
                  </div>
                </form>
                <mat-divider [inset]="true"></mat-divider>
                <button mat-raised-button color="default" (click)="signInWithGoogle()">Sign in with Google</button>
                <!-- <button mat-raised-button color="default" (click)="signInWithFacebook()">Sign in with Facebook</button> -->
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  `,
})
export class SignInPageComponent {
  form = this.fb.group({
    email: [null, Validators.required],
    password: [null, Validators.required],
  });

  constructor(private fb: FormBuilder, private router: Router, private coreService: CoreService) {}

  signIn() {
    if (this.form.invalid) {
      return;
    }

    this.form.disable();
    const credentials = this.form.value;

    this.coreService.signIn(credentials).subscribe(
      async (res) => {
        if (res) {
          await this.coreService.init();
          this.router.navigate(['/']);
        } else {
          alert('Sign in failed');
        }
        this.form.enable();
      },
      (error) => {
        alert('Sign in failed');
        this.form.enable();
      }
    );
  }

  signInWithGoogle() {
    location.href = '/api/google-sign-in';
  }

  signInWithFacebook() {
    location.href = '/api/facebook-sign-in';
  }
}
