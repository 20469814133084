import { ChangeDetectionStrategy, Component, AfterViewInit } from '@angular/core';
import { map, tap, catchError, retry } from 'rxjs/operators';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-dashboard',
  template: ` <h1>Dashboard</h1> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements AfterViewInit {
  messages$ = this.service.messages$.pipe(
    map((data: any) => {
      return data.hello;
    }),
    catchError((error) => {
      throw error;
    }),
    tap({
      error: (error) => console.log('Error:', error),
      complete: () => console.log('Connection Closed'),
    })
  );

  constructor(private service: DataService) {}

  ngAfterViewInit() {
    this.service.connect();
  }

  send() {
    this.service.sendMessage('hello');
  }

  close() {
    this.service.close();
  }
}
