import { Fixture, FixturePrediction } from './../models/index';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FixtureService, PredictionService, TeamService } from '../services';

@Component({
  selector: 'app-fixture-row',
  template: `
    <form [formGroup]="predictionForm" autocomplete="off" novalidate>
      <div fxShow.lt-sm="false" fxLayout="row" class="mat-row x-row">
        <div fxFlex="20%" class="mat-cell">
          {{ fixture!.predictionEndsAt | date: 'dd.MM.yy HH:mm' }}
        </div>
        <div fxFlex="30%" class="mat-cell">
          <a [routerLink]="['/predictions', fixture!.id]">{{ homeTeam?.nameEt }} - {{ awayTeam?.nameEt }}</a>
        </div>
        <div fxFlex="10%" class="mat-cell">{{ fixture?.homeScore | hasValue: '_' }} - {{ fixture?.awayScore | hasValue: '_' }}</div>
        <div fxFlex="20%" class="mat-cell" *ngIf="canPredict">
          <mat-form-field style="width: 30px">
            <input matInput placeholder="" formControlName="homeScore" style="text-align: right" />
          </mat-form-field>
          -&nbsp;
          <mat-form-field style="width: 30px">
            <input matInput placeholder="" formControlName="awayScore" />
          </mat-form-field>
        </div>
        <div fxFlex="20%" class="mat-cell" *ngIf="!canPredict">
          <span *ngIf="prediction && prediction.homeScore != null">{{ prediction.homeScore }}</span> -
          <span *ngIf="prediction && prediction.awayScore != null">{{ prediction.awayScore }}</span>
        </div>
        <div fxFlex="10%" class="mat-cell">
          {{ prediction?.points | hasValue: '-' }}
        </div>
        <div *ngIf="canPredict" fxFlex="10%" class="mat-header-cell" fxLayoutAlign="end">
          <a mat-raised-button (click)="save()" color="accent">Ennusta</a>
        </div>
      </div>
      <!-- lt-md -->
      <div style="padding-top: 10px">
        <mat-card fxShow.gt-xs="false" style="background-color: rgb(63, 81, 181, 0.15)">
          <div fxLayout="row">
            <span fxFlex="120px">Algus:</span>
            <span>{{ fixture!.predictionEndsAt | date: 'dd.MM.yy HH:mm' }}</span>
          </div>
          <div fxLayout="row">
            <span fxFlex="120px">Mäng:</span>
            <a [routerLink]="['/predictions', fixture!.id]">{{ homeTeam?.nameEt }} - {{ awayTeam?.nameEt }}</a>
          </div>
          <div fxLayout="row">
            <span fxFlex="120px">Tulemus:</span>
            <span>{{ fixture?.homeScore | hasValue: '_' }} - {{ fixture?.awayScore | hasValue: '_' }}</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex="120px" style="vertical-align: middle">Minu ennustus:</span>
            <div *ngIf="canPredict">
              <mat-form-field style="width: 30px">
                <input matInput placeholder="" formControlName="homeScore" style="text-align: right" />
              </mat-form-field>
              -&nbsp;
              <mat-form-field style="width: 30px">
                <input matInput placeholder="" formControlName="awayScore" />
              </mat-form-field>
            </div>
            <div *ngIf="!canPredict">{{ prediction?.homeScore | hasValue: '_' }} - {{ prediction?.awayScore | hasValue: '_' }}</div>
          </div>
          <div fxLayout="row">
            <span fxFlex="120px">Minu tulemus: </span>
            <span>{{ prediction?.points | hasValue: '-' }}</span>
          </div>
          <div fxLayout="row" *ngIf="canPredict" style="margin-top: 5px">
            <span fxFlex="120px"></span>
            <a mat-raised-button (click)="save()" color="accent">Ennusta</a>
          </div>
        </mat-card>
      </div>
    </form>
  `,
})
export class FixtureRowComponent implements OnInit {
  @Input() fixture: Fixture | undefined;
  @Input() canPredict: boolean | undefined;

  prediction?: FixturePrediction;
  homeTeam: any;
  awayTeam: any;

  predictionForm: FormGroup;

  constructor(
    private fixtureService: FixtureService,
    private predictionService: PredictionService,
    private teamService: TeamService,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar
  ) {
    this.predictionForm = this.formBuilder.group({
      homeScore: [null],
      awayScore: [null],
    });
  }

  ngOnInit() {
    this.homeTeam = this.teamService.getById(this.fixture!.homeTeamId!);
    this.awayTeam = this.teamService.getById(this.fixture!.awayTeamId!);
    this.predictionService.userPredictions$.subscribe((predictions) => {
      if (!predictions) {
        return;
      }
      this.prediction = predictions.find((p: any) => p.fixtureId === this.fixture!.id);
      if (!this.prediction) {
        this.prediction = {};
      }
      this.predictionForm.patchValue({
        homeScore: this.prediction.homeScore,
        awayScore: this.prediction.awayScore,
      });
    });
  }

  save() {
    const p = Object.assign({}, this.prediction, this.predictionForm.value);
    p.fixtureId = this.fixture!.id;
    this.fixtureService.savePrediction(p);
    this.snackBar.open('Ennustatud');
  }
}
