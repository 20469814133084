import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { MessageService } from '../services';

@Component({
  selector: 'app-message-add',
  template: `
    <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off" novalidate>
      <input hidden type="file" #uploader (change)="setFile($event)" accept="image/*" />
      <div style="{{ parent ? 'margin-top: 5px' : '' }}">
        <mat-form-field fxFlex appearance="fill">
          <textarea
            *ngIf="parent"
            [cdkTextareaAutosize]
            matInput
            (keydown.enter)="submit(); (false)"
            formControlName="content"
            placeholder="Kirjuta vastus"
          ></textarea>
          <textarea
            *ngIf="!parent"
            [cdkTextareaAutosize]
            matInput
            (keydown.enter)="submit(); (false)"
            formControlName="content"
            placeholder="Kirjuta oma sõnum"
          ></textarea>
          <button type="button" mat-icon-button matSuffix [attr.aria-label]="'Upload'" title="Upload" (click)="uploader.click()">
            <mat-icon>attach_file</mat-icon>
          </button>
          <button mat-icon-button color="primary" matSuffix [attr.aria-label]="'Saada'">
            <mat-icon>send</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </form>
    <small *ngIf="file">Üleslaadimiseks: {{ file.name }}</small>
  `,
  styles: [
    `
      ::ng-deep mat-form-field {
        margin-bottom: -1.25em;
      }
    `,
  ],
})
export class MessageAddComponent {
  @Input() parent: any;
  form: FormGroup = this.formBuilder.group({ content: '' });
  file?: File;

  constructor(private formBuilder: FormBuilder, private messageService: MessageService) {}

  submit() {
    const content = this.form.value.content;
    if (content) {
      const parentId = this.parent ? this.parent.id : null;
      this.messageService.addMessage({ content, parentId }, this.file);
      this.form.reset();
      this.file = undefined;
    }
  }

  setFile($event: any) {
    this.file = $event.target.files[0];
  }
}
