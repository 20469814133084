import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HeaderToolbarComponent, MessageAddComponent, MessageViewComponent } from './components';
import { AuthGuardService, MessageService, UserService } from './services';
import { DashboardComponent, MessageBoardPageComponent, SignInPageComponent, SignUpPageComponent, UserProfilePageComponent } from './views';

const routes: Routes = [
  { path: 'sign-in', component: SignInPageComponent },
  { path: 'sign-up', component: SignUpPageComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'message-board', component: MessageBoardPageComponent, canActivate: [AuthGuardService] },
  { path: 'user-profile', component: UserProfilePageComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  declarations: [
    DashboardComponent,
    MessageBoardPageComponent,
    SignInPageComponent,
    SignUpPageComponent,
    HeaderToolbarComponent,
    MessageAddComponent,
    MessageViewComponent,
    UserProfilePageComponent,
  ],
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [HeaderToolbarComponent],
  providers: [MessageService, UserService],
})
export class CoreModule {}
