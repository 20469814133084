import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreService } from '../../core/services';
import { Cup } from '../models';
import { CupService, TeamService } from '../services';
import { User } from './../../core/models';

@Component({
  selector: 'app-user-cup-predicton',
  template: `
    <mat-card *ngIf="canPredict">
      <span>{{ cup?.nameEt }} võitja ja kuldse saapa ennustamine</span>
      <form [formGroup]="form" (submit)="save()">
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="10px">
          <mat-form-field>
            <mat-select placeholder="Võitja" formControlName="winner" [compareWith]="teamService.compareById">
              <mat-option *ngFor="let team of teamService.teams | async" [value]="team">
                {{ team.nameEt }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Kuldne saabas</mat-label>
            <input matInput formControlName="goldenBoot" />
          </mat-form-field>
          <div fxFlex>
            <button mat-raised-button color="accent">Ennusta</button>
          </div>
        </div>
      </form>
    </mat-card>
    <mat-card *ngIf="cupPredictionData && !canPredict">
      Võitja: <strong>{{ cupPredictionData.winner?.nameEt }}</strong
      >. Kuldne saabas: <strong>{{ cupPredictionData.goldenBoot }}</strong>
    </mat-card>
  `,
})
export class UserCupPredictionComponent implements OnInit, OnDestroy {
  unsubscriber$: Subject<boolean> = new Subject<boolean>();
  @Input() user?: User;
  cupPredictionData: any;
  canPredict = false;
  form: FormGroup = {} as FormGroup;
  cup: Cup | null = null;

  constructor(private fb: FormBuilder, private cupService: CupService, private coreService: CoreService, public teamService: TeamService) {}

  ngOnInit() {
    // need to wait/react on cup changes
    this.cupService.cup.pipe(takeUntil(this.unsubscriber$)).subscribe((cup) => {
      this.cup = cup;
      if (cup) {
        this.cupService.loadCupPredictionByUserId(this.user!.id);
        if (new Date(cup.predictionEndsAt!) > new Date()) {
          this.canPredict = !!this.user && this.user?.id == this.coreService.signedInUser.value?.id;
        }
      }
    });

    this.cupService.cupPrediction.pipe(takeUntil(this.unsubscriber$)).subscribe((cupPrediction) => {
      if (cupPrediction?.data) {
        this.cupPredictionData = JSON.parse(cupPrediction.data);
        this.cupPredictionData.winner = this.teamService.getById(this.cupPredictionData.winnerId);
        this.form = this.fb.group({
          winner: [this.cupPredictionData.winner, Validators.required],
          goldenBoot: [this.cupPredictionData.goldenBoot, Validators.required],
        });
      } else {
        this.form = this.fb.group({
          winner: [null, Validators.required],
          goldenBoot: ['', Validators.required],
        });
      }
    });
  }

  save(): void {
    console.log('valid?', this.form.invalid);
    if (this.form.invalid) {
      return;
    }
    const data = JSON.stringify({
      winnerId: this.form.value.winner.id,
      goldenBoot: this.form.value.goldenBoot,
    });
    this.cupService.saveCupPrediction(data);
  }

  ngOnDestroy() {
    this.unsubscriber$.next(true);
    this.unsubscriber$.unsubscribe();
  }
}
