import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CoreService } from './core.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(public coreService: CoreService, public router: Router) {}

  async canActivate(): Promise<boolean> {
    if (!this.coreService.signedInUser.getValue()) {
      await this.router.navigate(['sign-in']);
      return false;
    }
    return true;
  }
}
