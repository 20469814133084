import { ChatChannel, ChatMessage } from '../models/index';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, EMPTY, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { CoreService } from './core.service';

@Injectable()
export class MessageService {
  private apiUrlChannel = '/api/core/chat-channels';
  private apiUrlMessage = '/api/core/chat-messages';

  channelsSubject = new BehaviorSubject<ChatChannel[] | null>(null);
  channelSubject = new BehaviorSubject<ChatChannel | null>(null);
  messagesSubject = new BehaviorSubject<ChatMessage[] | null>(null);

  lastSeenChatMessageId = new BehaviorSubject<number>(Number.MAX_SAFE_INTEGER);
  maxDisplayedChatMessageId = 0;

  constructor(private httpClient: HttpClient, private coreService: CoreService) {}

  loadChannels(): void {
    const user = this.coreService.signedInUser.value;
    let lastSeen = +user?.lastSeenChatMessageId!;
    if (this.maxDisplayedChatMessageId > lastSeen) {
      // incase or user pressed refresh
      lastSeen = this.maxDisplayedChatMessageId;
    }
    this.lastSeenChatMessageId.next(lastSeen);
    this.httpClient
      .get<ChatChannel[]>(this.apiUrlChannel)
      .pipe(take(1))
      .subscribe((channels) => {
        channels.map((c) => {
          if (c.lastMessageId) {
            c.lastMessageId = +c.lastMessageId; // convert to number
            if (this.maxDisplayedChatMessageId < c.lastMessageId) {
              this.maxDisplayedChatMessageId = c.lastMessageId;
            }
            c.hasUnread = lastSeen < c.lastMessageId!;
          } else {
            c.hasUnread = false;
          }
        });
        this.channelsSubject.next(channels);
        if (channels.length && !this.channelSubject.value) {
          this.channelSubject.next(channels[0]);
        }
        setTimeout(() => this.coreService.markAllChatMessagesAsRead(this.maxDisplayedChatMessageId), 2000);
      });
  }

  loadMessages(channelId: number): void {
    this.coreService.setLoading.next(true);
    this.httpClient
      .get<ChatMessage[]>(`${this.apiUrlChannel}/${channelId}/chat-messages`)
      .pipe(take(1), catchError(this.coreService.handleHttpError))
      .subscribe((messages) => {
        this.messagesSubject.next(messages);
        this.coreService.setLoading.next(false);
      });
  }

  addMessage(message: any, file?: File): void {
    const channel = this.channelSubject.getValue();
    if (file) {
      const formData = new FormData();
      const jsonBlob = new Blob([JSON.stringify(message)], {
        type: 'application/json',
      });
      formData.append('message', jsonBlob, 'message.json');
      formData.append('file', file!, file?.name!);
      this.httpClient
        .post<ChatMessage>(`${this.apiUrlChannel}/${channel!.id}/chat-messages-with-file`, formData)
        .pipe(take(1), catchError(this.addMessageFailed))
        .subscribe((message) => {
          this.loadMessages(channel!.id!);
        });
    } else {
      this.httpClient
        .post<ChatMessage>(`${this.apiUrlChannel}/${channel!.id}/chat-messages`, message)
        .pipe(take(1), catchError(this.addMessageFailed))
        .subscribe((message) => this.loadMessages(channel!.id!));
    }
  }

  addMessageFailed = () => {
    // error.status === 0
    this.coreService.error.next('Sõnumi saatmine ebaõnnestus');
    return EMPTY;
  };

  saveMessage(message: any): void {
    this.httpClient.put(`${this.apiUrlMessage}/${message.id}`, message).pipe(take(1)).subscribe();
  }
}
