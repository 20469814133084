import { User } from './../models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class UserService {
  private apiUrl = '/api/core/users';

  userSubject = new BehaviorSubject<User | null>(null);
  // TODO: remove this
  user$ = this.userSubject.asObservable();

  constructor(private httpClient: HttpClient) {}

  loadUser(id: number): void {
    this.userSubject.next(null);
    this.httpClient
      .get<User>(`${this.apiUrl}/${id}`)
      .pipe(take(1))
      .subscribe((user) => this.userSubject.next(user));
  }
}
