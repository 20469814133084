import { Component, OnInit, OnDestroy } from '@angular/core';
import { PredictionService } from '../services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-results-page',
  template: `
    <mat-card>
      <mat-card-title>Tulemused</mat-card-title>
      <mat-card-content>
        <div class="mat-elevation-z8" *ngIf="results != null" style="max-width: 600px">
          <div class="mat-table">
            <div fxLayout="row" class="mat-header-row x-header-row">
              <div fxFlex="28%" class="mat-header-cell">Ennustaja</div>
              <div fxFlex="12%" class="mat-header-cell">0</div>
              <div fxFlex="12%" class="mat-header-cell">1</div>
              <div fxFlex="12%" class="mat-header-cell">3</div>
              <div fxFlex="12%" class="mat-header-cell">4</div>
              <div fxFlex="12%" class="mat-header-cell">5</div>
              <div fxFlex="12%" class="mat-header-cell">Kokku</div>
            </div>
            <div fxLayout="row" class="mat-row x-row" *ngFor="let result of results">
              <div fxFlex="28%" class="mat-cell">
                {{ result.rank }}. <a [routerLink]="['/user-predictions', result.user.id]">{{ result.user.nickname }}</a>
              </div>
              <div fxFlex="12%" class="mat-cell">
                {{ result.p0 }}
              </div>
              <div fxFlex="12%" class="mat-cell">
                {{ result.p1 }}
              </div>
              <div fxFlex="12%" class="mat-cell">
                {{ result.p3 }}
              </div>
              <div fxFlex="12%" class="mat-cell">
                {{ result.p4 }}
              </div>
              <div fxFlex="12%" class="mat-cell">
                {{ result.p5 }}
              </div>
              <div fxFlex="12%" class="mat-cell">
                <strong>{{ result.total }}</strong>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
})
export class ResultsPageComponent implements OnInit, OnDestroy {
  unsubscriber$: Subject<boolean> = new Subject<boolean>();
  results: any;

  constructor(public predictionService: PredictionService) {
    this.predictionService.loadResults();
  }

  ngOnInit() {
    this.predictionService.results$.pipe(takeUntil(this.unsubscriber$)).subscribe((res) => {
      if (!res) {
        return;
      }
      let i = 1;
      let last = 0;
      this.results = res.map((r: any) => {
        if (r.total < last) {
          i++;
        }
        last = r.total;
        r.rank = i;
        return r;
      });
    });
  }

  ngOnDestroy() {
    this.unsubscriber$.next(true);
    this.unsubscriber$.unsubscribe();
  }
}
