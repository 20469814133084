import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Cup, CupPrediction } from './../models/index';

@Injectable()
export class CupService {
  private apiUrl = '/api/cups';

  cup = new BehaviorSubject<Cup | null>(null);
  cupPrediction = new BehaviorSubject<CupPrediction | null>(null);

  constructor(private httpClient: HttpClient) {}

  loadById(id: number): void {
    this.cup.next(null);
    this.httpClient
      .get<Cup>(`${this.apiUrl}/${id}`)
      .pipe(take(1))
      .subscribe((cup) => this.cup.next(cup));
  }

  loadCupPredictionByUserId(userId: any): void {
    const cup = this.cup.value;
    this.cupPrediction.next(null);
    this.httpClient
      .get(`${this.apiUrl}/${cup!.id}/predictions?userId=${userId}`)
      .pipe(take(1))
      .subscribe((resp) => this.cupPrediction.next(resp));
  }

  saveCupPrediction(data: string): void {
    const cup = this.cup.value;
    this.cupPrediction.next(null);
    this.httpClient
      .put(`${this.apiUrl}/${cup!.id}/predictions`, { data })
      .pipe(take(1))
      .subscribe((resp) => this.cupPrediction.next(resp));
  }
}
