import { Component, OnInit } from '@angular/core';
import { CoreService } from '../services';

@Component({
  selector: 'app-header-toolbar',
  template: `
    <mat-toolbar color="primary">
      <button mat-icon-button fxShow fxHide.gt-sm="true" [matMenuTriggerFor]="menu" *ngIf="isUserAuthenticated">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item routerLink="/message-board"><mat-icon mat-list-icon>chat</mat-icon>&nbsp;Vestlus</a>
        <a mat-menu-item routerLink="/predictions"><mat-icon mat-list-icon>list</mat-icon>&nbsp;Ennustamine</a>
        <a mat-menu-item routerLink="/results"><mat-icon mat-list-icon>trending_up</mat-icon>&nbsp;Tulemused</a>
        <a mat-menu-item routerLink="/rules"><mat-icon mat-list-icon>description</mat-icon>&nbsp;Reeglid</a>
        <a mat-menu-item routerLink="/fixtures" *ngIf="user?.role === 'ROLE_ADMIN'"><mat-icon mat-list-icon>assignment</mat-icon>&nbsp;Mängud</a>
      </mat-menu>
      <div><a href="/" style="color: whitesmoke; text-decoration: none">Sahtel ennustab</a></div>
      <div *ngIf="isUserAuthenticated" fxHide fxShow.gt-sm="true">
        <a mat-button routerLink="/message-board"><mat-icon mat-list-icon>chat</mat-icon>&nbsp;Vestlus</a>
        <a mat-button routerLink="/predictions"><mat-icon mat-list-icon>list</mat-icon>&nbsp;Ennustamine</a>
        <a mat-button routerLink="/results"><mat-icon mat-list-icon>trending_up</mat-icon>&nbsp;Tulemused</a>
        <a mat-button routerLink="/rules"><mat-icon mat-list-icon>description</mat-icon>&nbsp;Reeglid</a>
        <a *ngIf="user?.role === 'ROLE_ADMIN'" mat-button routerLink="/fixtures"><mat-icon mat-list-icon>assignment</mat-icon>&nbsp;Mängud</a>
      </div>
      <span fxFlex="1 1 auto"></span>
      <span *ngIf="!isUserAuthenticated">
        <a mat-button mat-raised-button routerLink="sign-in" routerLinkActive="hide">Sign in</a>
        <a mat-button mat-raised-button routerLink="sign-up" routerLinkActive="hide">Sign up</a>
      </span>
      <span *ngIf="isUserAuthenticated">
        <button mat-icon-button [matMenuTriggerFor]="userMenu" [title]="user.nickname"><mat-icon>person</mat-icon></button>
        <mat-menu #userMenu="matMenu">
          <a mat-menu-item routerLink="user-profile">
            <mat-icon>account_circle</mat-icon>
            <span>Kasutaja andmed</span>
          </a>
          <button mat-menu-item (click)="signOut()">
            <mat-icon>exit_to_app</mat-icon>
            Sign out
          </button>
        </mat-menu>
      </span>
    </mat-toolbar>
  `,
  styles: [
    `
      .hide {
        display: none;
      }
    `,
  ],
})
export class HeaderToolbarComponent implements OnInit {
  isUserAuthenticated = false;
  user: any = null;

  constructor(public coreService: CoreService) {
    this.coreService.signedInUser.subscribe((user: any) => {
      this.user = user;
      this.isUserAuthenticated = !!user;
    });
  }

  async ngOnInit(): Promise<void> {
    this.user = this.coreService.signedInUser.getValue();
    this.isUserAuthenticated = !!this.user;
  }

  async signOut(): Promise<void> {
    await this.coreService.signOut();
  }
}
