import { ChatMessage } from './../models/index';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as marked from 'marked';
import { MessageService } from './../services';

const renderer = new marked.Renderer();
renderer.link = (href, title, text) => {
  return `<a target="_blank" href="${href}" title="${title ? title : ''}">${text}</a>`;
};

// renderer.image = (href, title, text) => {
//   return `<img width="350" style="display: block" src="${href}" title="${title ? title : ''}" alt="${text ? text : ''}" />`;
// };

@Component({
  selector: 'app-message-view',
  template: `
    <mat-card style="margin-left: {{ marginLeft }}; {{ replyCss }}">
      <mat-card-content>
        <div class="msg-header">
          <mat-icon color="accent" *ngIf="unread">arrow_right</mat-icon><span>{{ message.user.nickname }}</span>
        </div>
        <div class="msg-content" [innerHtml]="contentMd"></div>
        <div *ngFor="let file of message.files">
          <a href="/assets/public/{{ file.name }}" target="_blank">
            <img src="/assets/public/{{ file.thumbName }}" />
          </a>
        </div>
        <div style="font-size: 12px; color: grey; font-weight: normal">{{ message.createdAt | date: 'dd.MM.yy HH:mm' }}</div>
        <ng-container *ngIf="message.replies">
          <app-message-view [marginLeft]="'20px'" [message]="reply" *ngFor="let reply of message.replies"></app-message-view>
        </ng-container>
        <app-message-add *ngIf="message && !message.parentId" [parent]="message"></app-message-add>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    'div.msg-header {font-size: 1.2em; font-weight: bold;}',
    'div.msg-header mat-icon { vertical-align: middle; }',
    'div.msg-content { padding-top: 7px; padding-bottom: 0px }',
    'div.msg-content p { margin: 0 }',
    'div.msg-content img {display: block; width: 300px; height: auto}',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MessageViewComponent implements OnInit {
  @Input() message: any;
  @Input() marginLeft: any;
  contentMd = '';
  replyCss = '';
  unread = false;

  constructor(private messageService: MessageService) {}

  ngOnInit() {
    if (this.message.parentId) {
      this.replyCss = 'background-color: rgb(63, 81, 181, 0.15)';
    }
    this.contentMd = marked(this.message?.contentMd, { renderer: renderer });
    this.unread = this.messageService.lastSeenChatMessageId.value < +this.message.id;
  }
}
