import { Component } from '@angular/core';

@Component({
  selector: 'app-rules-page',
  template: `
    <mat-card>
      <mat-card-title>EM 2021 Ennustusvõistluse reeglid</mat-card-title>
      <mat-card-content>
        <ol>
          <li>
            Ennustusvõistluse eesmärk on koguda punkte. Ennustusvõistluse võidab osaleja, kes on turniiri lõpuks kogunud kõige rohkem punkte. Võrdsete punktide
            korral võrreldakse finaalis teenitud punkte, seejärel poolfinaalides jne.
          </li>
          <li>Ennustustatakse mängu lõppskoori. Play-off mängude puhul ennustatakse lõppskoori koos võimaliku lisaajaga ja ilma mängujärgsete penaltiteta.</li>
          <li>
            Võitja, viigi ja edasipääseja täpne ennustamine annab 3 punkti. Kummagi võistkonna löödud väravate arvu täpne ennustamine annab 1 punkti sõltumata
            mängutulemusest. Kokku on 1 mänguga võimalik teenida kuni 5 punkti.
          </li>
          <li>Ennustada saab igat mängu kuni avavile ettemääratud kellaajani.</li>
          <li>
            Eraldi ennustatakse enne esimese mängu algust turniiri parimat väravalööjat ja maailmameistrit. Täpse ennustamise puhul annab kumbki kategooria 10
            lisapunkti.
          </li>
          <li>Ennustusvõistluse võitja auhinnaks on...</li>
        </ol>
      </mat-card-content>
    </mat-card>
  `,
})
export class RulesPageComponent {}
