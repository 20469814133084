import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CoreService } from '../../core/services';
import { Cup, Fixture, FixturePrediction } from '../models';
import { CupService } from './cup.service';

@Injectable()
export class FixtureService {
  private apiUrl = '/api/cups/fixtures';

  fixtures = new BehaviorSubject<Fixture[] | null>(null);
  fixturesToCome = new BehaviorSubject<Fixture[] | null>(null);
  fixturesDone = new BehaviorSubject<Fixture[] | null>(null);
  selectedFixture = new BehaviorSubject<Fixture | null>(null);

  private cup: Cup | null = null;

  constructor(private httpClient: HttpClient, private cupService: CupService, private coreService: CoreService) {
    this.cupService.cup.subscribe((cup) => (this.cup = cup));
  }

  loadFixtures(): void {
    this.coreService.setLoading.next(true);
    this.fixtures.next(null);
    this.httpClient
      .get<Fixture[]>(this.apiUrl)
      .pipe(take(1))
      .subscribe((fixtures) => {
        fixtures.map((f) => (f.predictionEndsAt = f.predictionEndsAt ? new Date(f.predictionEndsAt) : undefined));
        this.fixtures.next(fixtures);

        const now = new Date();
        this.fixturesToCome.next(fixtures.filter((f) => f.predictionEndsAt && f.predictionEndsAt > now));
        const done = fixtures.filter((f) => f.predictionEndsAt && f.predictionEndsAt <= now);
        this.fixturesDone.next(done.reverse());
        this.coreService.setLoading.next(false);
      });
  }

  loadFixture(id: number): void {
    this.selectedFixture.next(null);
    this.httpClient
      .get(`${this.apiUrl}/${id}`)
      .pipe(take(1))
      .subscribe((fixtures) => this.selectedFixture.next(fixtures));
  }

  create(fixture: any): void {
    this.fixtures.next(null);
    this.httpClient
      .post(this.apiUrl, fixture)
      .pipe(take(1))
      .subscribe((fixtures) => this.loadFixtures());
  }

  update(fixture: any): void {
    this.fixtures.next(null);
    this.httpClient
      .put(`${this.apiUrl}/${fixture.id}`, fixture)
      .pipe(take(1))
      .subscribe(() => this.loadFixtures());
  }

  savePrediction(prediction: FixturePrediction): void {
    this.httpClient.post(`${this.apiUrl}/${prediction.fixtureId}/predictions`, prediction).pipe(take(1)).subscribe();
  }
}
