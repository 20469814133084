import { Component } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../models';

import { CoreService, UserService } from '../services';

@Component({
  selector: 'app-message-board-page',
  template: `
    <mat-card>
      <mat-card-title>Kasutaja andmed</mat-card-title>
      <mat-card-content>
        <br />
        <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off" novalidate>
          <div fxLayout="column" fxLayoutAlign="space-between none" fxLayoutGap="20px">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Kasutaja nimi</mat-label>
              <input matInput formControlName="nickname" />
              <mat-error>Väli on kohustuslik</mat-error>
              <mat-hint>Nimi, mida kuvatakse teistele kasutajatele</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" />
              <mat-hint>Emaili ei saa muuta</mat-hint>
            </mat-form-field>
          </div>
          <br />
          <button mat-raised-button color="primary" [disabled]="form.invalid">Salvesta</button>
        </form>
      </mat-card-content>
    </mat-card>
  `,
})
export class UserProfilePageComponent {
  form: FormGroup;
  constructor(private fb: FormBuilder, private snackBar: MatSnackBar, private coreService: CoreService) {
    const user = this.coreService.signedInUser.getValue()!;
    this.form = this.fb.group({
      nickname: [user.nickname, Validators.required],
      email: [{ value: user.email, disabled: true }],
    });
  }

  async submit() {
    if (this.form.invalid) {
      return;
    }
    const user = { nickname: this.form.value.nickname } as User;
    this.coreService.saveCurrentUser(user).subscribe(
      (resp) => {
        this.snackBar.open('Salvestatud');
        console.log(resp);
      },
      () => {
        this.snackBar.open('Salvestamine ebaõnnestus');
      }
    );
  }
}
