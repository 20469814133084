import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Inject } from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoreService } from './core/services';

@Component({
  selector: 'app-root',
  template: `
    <app-header-toolbar></app-header-toolbar>
    <div ngClass.gt-xs="main">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: ['.main { margin: 20px 10px }'],
})
export class RootComponent {
  title = 'ZScore';
  private spinnerTopRef: OverlayRef;
  private spinners = 0;

  constructor(private coreService: CoreService, private overlay: Overlay, public dialog: MatDialog) {
    this.spinnerTopRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });

    this.coreService.setLoading.subscribe((res) => {
      if (res) {
        this.spinners++;
        if (!this.spinnerTopRef.hasAttached()) {
          this.showSpinner();
        }
      } else if (this.spinners > 0) {
        this.spinners--;
        if (this.spinners === 0 && this.spinnerTopRef.hasAttached()) {
          this.stopSpinner();
        }
      }
    });

    this.coreService.error.subscribe((error) => {
      this.dialog.open(ErrorDialogComponent, { data: error });
    });
  }

  private showSpinner() {
    this.spinnerTopRef.attach(new ComponentPortal(MatSpinner));
  }

  private stopSpinner() {
    this.spinnerTopRef.detach();
  }
}

@Component({
  selector: 'app-error-dialog',
  template: `
    <span mat-dialog-title>Viga</span>
    <div mat-dialog-content style="min-width: 150px; max-width: 400px">
      {{ data }}
    </div>
    <mat-dialog-actions>
      <button mat-button mat-dialog-close>OK</button>
    </mat-dialog-actions>
  `,
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}
}
