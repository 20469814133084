import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Component, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from './core/core.module';
import { ApiRequestInterceptor, CoreService, MessageService } from './core/services';
import { CupModule } from './cup/cup.module';
import { CupService, TeamService } from './cup/services';
import { ErrorDialogComponent, RootComponent } from './root.component';
import { SharedModule } from './shared/shared.module';

export function StartupServiceFactory(coreService: CoreService, messageService: MessageService, teamService: TeamService, cupService: CupService): () => void {
  return async () => {
    await coreService.init();
    messageService.loadChannels();
    teamService.load();
    cupService.loadById(1);
  };
}

@Component({
  template: `<h1>Page Not Found</h1>`,
})
export class ViewNotFoundPageComponent {}

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'message-board' },
  { path: '**', component: ViewNotFoundPageComponent },
];

@NgModule({
  declarations: [RootComponent, ViewNotFoundPageComponent, ErrorDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    CupModule,
    RouterModule.forRoot(routes),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: StartupServiceFactory,
      deps: [CoreService, MessageService, TeamService, CupService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRequestInterceptor,
      multi: true,
    },
  ],
  bootstrap: [RootComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [ErrorDialogComponent],
})
export class AppModule {}
