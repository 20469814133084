import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FixtureService, TeamService } from '../services';

@Component({
  selector: 'app-fixture-row-admin',
  template: `
    <form [formGroup]="fixtureForm" autocomplete="off" novalidate>
      <div fxLayout="row" class="mat-row x-row">
        <div fxFlex="30%" class="mat-cell">
          <mat-form-field style="width: 100px">
            <input matInput [matDatepicker]="predictionEndsAt" placeholder="Algus" formControlName="predictionEndsDate" />
            <mat-datepicker-toggle matSuffix [for]="predictionEndsAt"></mat-datepicker-toggle>
            <mat-datepicker #predictionEndsAt></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 60px">
            <mat-select formControlName="predictionEndsTime">
              <mat-option *ngFor="let time of timeList" [value]="time">{{ time }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="30%" class="mat-cell">
          <mat-form-field style="width: 100px">
            <mat-select placeholder="Kodumeeskond" formControlName="homeTeam" [compareWith]="teamService.compareById">
              <mat-option *ngFor="let team of teamService.teams | async" [value]="team">
                {{ team.nameEt }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          -&nbsp;
          <mat-form-field style="width: 100px">
            <mat-select placeholder="Võõrsilmeeskond" formControlName="awayTeam" [compareWith]="teamService.compareById">
              <mat-option *ngFor="let team of teamService.teams | async" [value]="team">
                {{ team.nameEt }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="10%" class="mat-cell">
          <mat-form-field style="width: 30px">
            <input matInput placeholder="" formControlName="homeScore" style="text-align: right" />
          </mat-form-field>
          -&nbsp;
          <mat-form-field style="width: 30px">
            <input matInput placeholder="" formControlName="awayScore" />
          </mat-form-field>
        </div>
        <div fxFlex="20%" class="mat-cell">
          <mat-form-field style="width: 100px">
            <mat-select placeholder="Võitja" formControlName="winnerId" [compareWith]="teamService.compareById">
              <mat-option></mat-option>
              <mat-option *ngIf="homeTeam" [value]="fixture.homeTeamId">{{ homeTeam.nameEt }}</mat-option>
              <mat-option *ngIf="awayTeam" [value]="fixture.awayTeamId">{{ awayTeam.nameEt }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="10%" class="mat-header-cell" fxLayoutAlign="end">
          <a *ngIf="!fixture.pointsCalculatedAt" mat-raised-button (click)="save()" color="accent">Salvesta</a>
        </div>
      </div>
    </form>
  `,
})
export class FixtureRowAdminComponent implements OnInit {
  @Input() fixture: any;
  timeList = ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00'];

  fixtureForm: FormGroup = {} as FormGroup;
  homeTeam: any;
  awayTeam: any;

  constructor(private fixtureService: FixtureService, public teamService: TeamService, private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.homeTeam = this.teamService.getById(this.fixture.homeTeamId);
    this.awayTeam = this.teamService.getById(this.fixture.awayTeamId);
    const d = new Date(this.fixture.predictionEndsAt);
    const dStr = d.getHours() + ':00'; // + (d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes());
    this.fixtureForm = this.formBuilder.group({
      predictionEndsDate: [this.fixture.predictionEndsAt],
      predictionEndsTime: [dStr],
      homeTeam: [this.homeTeam],
      awayTeam: [this.awayTeam],
      homeScore: [this.fixture.homeScore],
      awayScore: [this.fixture.awayScore],
      winnerId: [this.fixture.winnerId],
    });
  }

  save() {
    const predictionEndsAt = new Date(this.fixtureForm.value.predictionEndsDate);
    const time = this.fixtureForm.value.predictionEndsTime;
    if (time) {
      predictionEndsAt.setHours(time.substring(0, 2));
    } else {
      predictionEndsAt.setHours(22);
    }
    predictionEndsAt.setMinutes(0);
    predictionEndsAt.setSeconds(0);
    predictionEndsAt.setMilliseconds(0);

    const formValue = this.fixtureForm.value;
    const fixture = {
      id: this.fixture.id,
      homeTeamId: formValue.homeTeam.id,
      awayTeamId: formValue.awayTeam.id,
      homeScore: formValue.homeScore,
      awayScore: formValue.awayScore,
      winnerId: formValue.winnerId,
      predictionEndsAt,
    };

    this.fixtureService.update(fixture);
  }
}
